<template>
  <ul class="tabs-block">
    <li v-for="tab in tabsNames" @click="goTo(tab)" :class="{ 'tabs-block__active': $route.name === tab.linkName }">{{ tab.name }}</li>
  </ul>
</template>

<script>
export default {
  name: "tabs",
  props: {
    tabsNames: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    goTo(tab) {
      this.$router.push({name: tab.linkName});
    }
  },
  mounted() {
    this.$emit('firstTabActive', this.$route.name === this.tabsNames[0].linkName)
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.tabs-block {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
  position: sticky;
  left: 0;

  li {
    display: flex;
    padding: 16px 20px;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    color: $grey_1;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.44px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover {
      color: $green !important;
      &:not(.tabs-block__active) {
        border-bottom: 1px solid $green;
      }
    }
  }

  &__active {
    color: $green !important;
    border-radius: 6px 6px 0 0;
    background: #FFFFFF;
  }
}
</style>
