import * as type from './types';
import actions from './actions';
import api from '../../../api';

const state = {
  myUser: {},
  menuShown: true,
};

const mutations = {
  [type.GET_MY_USER](state, payload) {
    state.myUser = payload
  },
    [type.TOGGLE_MENU](state, payload) {
        state.menuShown = payload
    },
};

const getters = {
};

export default {
    state,
    mutations,
    actions,
    getters,
};
