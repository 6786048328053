<template>
  <div class="page-container">
    <div class="page-header">
      <div class="title" >
        <img src="../assets/menu-icons/bill-active.svg" alt="reports-icon">
        <span class="text">
            Платежи
        </span>
      </div>
    </div>
    <div class="page-content">
      <div class="tab-content table">
        <TableComponent
            v-if="userRole === 1"
            :paymentsActions="true"
            :paymentsActions2="true"
            :noData="noData"
            :columns="columns"
            :rows="tableRows_1"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            :title="'Платежи'"
            :isBordered="true"
            :show-additional-table-header="false"
            :isLightFontAdditional="true"
            :additional-rows="additionalRows_1"
            :datePicker="true"
            @downloadXSLX="downloadXSLX"
            @uploadXSLX="uploadXSLX"
            @regetData="regetData($event)"
            :actionType="'paymentsActions'"
        />
        <TableComponent
            v-if="userRole === 2"
            :paymentsActions="true"
            :noData="noData"
            :columns="columns"
            :rows="tableRows_1"
            :totalCount="totalCount"
            :pageSize="pageSize"
            :pageCount="pageCount"
            :currentPage="Number(currentPage)"
            :isLoading="loading"
            :title="'Платежи'"
            :isBordered="true"
            :show-additional-table-header="false"
            :isLightFontAdditional="true"
            :additional-rows="additionalRows_1"
            :datePicker="true"
            @downloadXSLX="downloadXSLX"
            @uploadXSLX="uploadXSLX"
            @regetData="regetData($event)"
            :actionType="'paymentsActions'"
        />
        <input id="txFileUpload" @input="addFile($event)" type="file" multiple accept=".xlsx, .xls" hidden>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/tabs.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Payments",
  components: {
    Tabs
  },
  data() {
    return {
      noData: false,
      tableColumns_1: [
        {
          name: 'Наименование клиента',
          field: 'client_name',
          filter_name: 'customer_id',
          filter_value: '',
          order: ''
        },
        // {
        //   name: 'Дата платежа',
        //   field: 'date',
        //   filter_name: 'date',
        //   filter_value: '',
        //   order: '',
        //   date_type: true
        // },
        {
          name: 'Дата входящего документа',
          field: 'date',
          filter_name: 'date',
          filter_value: '',
          order: '',
          date_type: true
        },
        {
          name: 'Дата платежа',
          field: 'payment_date',
          filter_name: 'payment_date',
          filter_value: '',
          order: '',
          date_type: true
        },
        {
          name: 'Сумма',
          field: 'sum',
          filter_name: 'sum',
          filter_name_from: 'sum_from',
          filter_value_from: '',
          filter_name_to: 'sum_to',
          filter_value_to: '',
          from_to_fields: true,
          order: ''
        },
        {
          name: '№ платежного поручения',
          field: 'payment_order_number',
          filter_name: 'payment_order_number',
          filter_value: '',
          order: ''
        },
        {
          name: '№ входящего документа',
          field: 'contract_number',
          filter_name: 'contract_number',
          filter_value: '',
          order: ''
        },
        {
          name: 'ИНН',
          field: 'inn',
          filter_name: 'inn',
          filter_value: '',
          order: ''
        },
        // {
        //   name: 'Реквизиты плательщика',
        //   field: 'payer_details',
        //   filter_name: 'payer_details',
        //   filter_value: '',
        //   order: ''
        // },
        {
          name: 'Назначение',
          field: 'payments_purpose',
          filter_name: 'payments_purpose',
          filter_value: '',
          order: ''
        }
      ],
      tableRows_1: [],
      additionalRows_1: [
        {
          client_name: 'Итого',
          itn: '',
          payment_order_number: '',
          payment_date: '',
          sum: '550 000,00',
          payer_details: '',
          destination: '',
          strong_text: 'client_name'
        }
      ],
      loading: false,
      totalCount: 0,
      pageSize: 0,
      pageCount: 0,
      currentPage: 0,
    }
  },
  watch: {
    async payments(val) {
      await this.setTablesData()
    },
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      userRole: state => state.user.userRole,
      columns: state => state.popups.columns,
      payments: state => state.admin.payments,
    }),
    tabs() {
      switch (this.userRole) {
        case 3: return [this.tabsNames[0], this.tabsNames[1]];
        case 2: return [this.tabsNames[0], this.tabsNames[2], this.tabsNames[3]]
      }
    }
  },
  methods: {
    ...mapActions({
      uploadPayments: 'uploadPayments',
      getPayments: 'getPayments',
      downloadXLSXFile: 'downloadXLSXFile',
      getIntegrations: 'getIntegrations',
    }),
    ...mapMutations({
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      resetPaginationFilters: 'RESET_PAGINATION_FILTERS',
    }),
    async regetData(data) {
      console.log(data)
      this.loading = true

      let args = [
        {
          name: 'page',
          value: data.pageNumber
        },
        {
          name: 'perPage',
          value: data.size
        }
      ]

      if (Math.ceil(this.totalCount/data.size) < data.pageNumber) {
        args[0].value = 1
      }

      this.setPaginationFilters(args)

      await this.getPayments()
    },
    async setTablesData() {
      this.totalCount = this.payments.total_count
      this.pageSize = this.payments.page_size
      this.pageCount = this.payments.page_count
      this.currentPage = this.payments.current_page

      this.additionalRows_1[0].sum = this.payments.total_sum

      let paymentsData = []

      for (let i = 0; i < this.payments.data.length; i++) {
        let data = this.payments.data[i]
        data.actionType = 'paymentsActions'
        data.client_name = data.customer?.user?.name
        data.inn = data.customer?.inn

        paymentsData.push(data)
      }

      this.tableRows_1 = paymentsData

      if (!this.tableRows_1 || this.tableRows_1.length === 0) {
        this.noData = true
      } else {
        this.noData = false
      }

      if (this.columns) {
        this.setColumns(this.columns)
      } else {
        this.setColumns(this.tableColumns_1)
      }

      this.loading = false
    },
    async downloadXSLX() {
      await this.getPayments(true)
    },
    uploadXSLX() {
      document.getElementById("txFileUpload").click()
    },
    async addFile(file) {
      console.log(file)
      console.log(file.target.files)
      this.files = file.target.files

      let formData = new FormData();
      //formData.append('files', file.target.files[0])

      //let filesArr = []
      for (let key in this.files) {
        if (this.files.hasOwnProperty(key)) {
          console.log(this.files[key])
          //filesArr.push(this.files[key])
          formData.append("files", this.files[key]);
        }
      }

      //formData.append("files[]", filesArr);


      await this.uploadPayments(formData)

    },
  },
  async mounted() {
    this.setColumns(null)
    this.setColumns_2(null)
    // setTimeout(() => {
    //   this.setColumns(this.tableColumns_1)
    // }, 1800)
    this.resetPaginationFilters()
    await this.getIntegrations();

    await this.getPayments()
    await this.setTablesData()
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";
.separator {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1px;
  background: $back;
  width: 100%;
}
.tab-content {
  margin-top: 30px;
}
</style>
