<template>
  <div>
    <span v-if="loading" class="loader">
      <svg class="circular" height="50" width="50">
        <circle class="path" cx="25" cy="25.2" r="19.9" fill="none" stroke-width="6" stroke-miterlimit="10" />
      </svg>
    </span>
    <div class="popup-wrap" v-show="showOverlay">

      <div class="modal-overlay"
           v-show="showOverlay"
           @click="closeAll"></div>

      <transition-group name="modal">
        <viewFile key="view-file" v-if="showViewFilePopup"/>
        <editClientPopup key="edit-client" v-if="showEditClientPopup"/>
        <removeClientPopup key="remove-client" v-if="showRemoveClientPopup"/>
        <setCardLimitPopup key="set-card-limit" v-if="showSetCardLimitPopup"/>
        <suspendCardPopup key="suspend-card" v-if="showSuspendCardPopup"/>
        <addCardPopup key="add-card" v-if="showAddCardPopup"/>
        <editUserPopup key="edit-user" v-if="showEditUserPopup"/>
        <removeUserPopup key="remove-user" v-if="showRemoveUserPopup"/>
        <removeFilePopup key="remove-file" v-if="showRemoveFilePopup"/>
        <addNewUserPopup key="add-new-user" v-if="showAddNewUserPopup"/>
        <uploadPricesPopup key="upload-prices" v-if="showUploadPricesPopup"/>
        <errorPopup key="error-popup" v-if="errorPopup"/>
        <resetUserPasswordPopup key="reset-password-popup" v-if="showResetPasswordPopup"/>
        <bunchChangeCardStatusPopup key="bunch-change-card-status-popup" v-if="showBunchChangeCardStatusPopup"/>
        <suretyInfo key="surety-info" v-if="showSuretyInfoPopup"/>
        <setDiscount key="set-discount" v-if="showDiscountPopup"/>
        <set-discount-fuel-type key="set-discount-fuel-type" v-if="showDiscountFuelTypePopup"/>
        <set-discount-region key="set-discount-region" v-if="showDiscountRegionPopup"/>
        <edit-client-card key="edit-client-card" v-if="showEditCardPopup"/>
        <remove-references-popup key="remove-references-popup" v-if="showRemoveReferencePopup"/>
        <upload-cards-from key="upload-cards-from" v-if="showUploadCardsPopup"/>
        <set-cards-for-client key="set-cards-for-client" v-if="showSetCardsForClientPopup"/>
        <integration-list key="integration-list" v-if="showIntegrationListPopup"/>
        <list-vinks-regions-fuel key="list-vinks-regions-fuel" v-if="showListVinksRegionsFuelPopup"/>
      </transition-group>
    </div>
    <!--FILTER POPUP-->
    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="filterPopupShown && columns"
         class="filter-popup"
         :class="{'last-one': filterPopupShown && filterPopupShown.index === columns.length - 1}"
         :style="{ top: filterPopupShown.menuY + 'px', left: filterPopupShown.menuX + 'px', height: !columns[filterPopupShown.index].filter_name ? '40px' : '' }"
    ><!--v-on-click-outside="closeFilterPopup(i)"-->
      <div class="order-filter">
        <span class="order-filter__title">Сортировать А/Я1</span>
        <button @click="changeOrder()" class="order-filter__btn">
          <orderFilterIcon
              :isActive="columns[filterPopupShown.index].order === 'asc' || columns[filterPopupShown.index].order === 'desc'"
              :order="columns[filterPopupShown.index].order"
          />
        </button>
      </div>
      <div class="name-filter" v-if="columns[filterPopupShown.index].filter_name">
        <span class="name-filter__title">{{ getFilterDesc() }}</span>
        <div class="input-wrap">
          <v-select :options="getSelectOptions"
                    :label="getSelectOptions.length > 0 && getSelectOptions[0]['name'] ? 'name' : 'number'"
                    v-model="columns[filterPopupShown.index].filter_value"
                    :clearable="false"
                    :searchable="true"
                    :reduce="columns[filterPopupShown.index].reducer ? option => option[columns[filterPopupShown.index].reducer] : option => option.id"
                    placeholder="Выбрать"
                    v-if="showSelect()"
                    @option:selected="onClickOutside(null, true)"
          >
            <template #no-options>
              Введите имя
            </template>
          </v-select>
          <v-select :options="getSelectOptions"
                    label="name"
                    v-model="columns[filterPopupShown.index].filter_value"
                    :clearable="false"
                    :searchable="true"
                    placeholder="Выбрать"
                    @search="fetchOptions"
                    @close="clearSelectOptions()"
                    v-else-if="showSelectSearch()"
                    @option:selected="onClickOutside(null, true)"
          >
            <template #no-options>
              Введите имя
            </template>
          </v-select>
          <VueDatePicker
            v-else-if="columns[filterPopupShown.index].date_type"
            v-model="columns[filterPopupShown.index].filter_value"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            locale="ru"
            placeholder="Дата"
            cancelText="Отмена"
            selectText="Выбрать"
            :clearable="false"
            class="popup-date-filter"
            @update:model-value="onClickOutside(null, true)"
          />
          <div v-else-if="columns[filterPopupShown.index].timepicker" class="popup__from-to">
            <div>
              <VueDatePicker
                  v-model="columns[filterPopupShown.index].filter_value_from"
                  time-picker
                  locale="ru"
                  placeholder="Время"
                  cancelText="Отмена"
                  selectText="Выбрать"
                  :clearable="true"
                  class="popup-date-filter"
                  @update:model-value="onClickOutside(null, true)"
              />
            </div>
            <div>
              <VueDatePicker
                  v-model="columns[filterPopupShown.index].filter_value_to"
                  time-picker
                  locale="ru"
                  placeholder="Время"
                  cancelText="Отмена"
                  selectText="Выбрать"
                  :clearable="true"
                  class="popup-date-filter"
                  @update:model-value="onClickOutside(null, true)"
              />
            </div>
          </div>
          <div v-else-if="columns[filterPopupShown.index].from_to_fields" class="popup__from-to">
            <div>
              <input v-model="columns[filterPopupShown.index].filter_value_from"
                     class="name-filter__input"
                     type="text"
                     @input="inputChange(columns[filterPopupShown.index].filter_value_from)"
                     placeholder="От">
              <button @click="columns[filterPopupShown.index].filter_value_from = ''; onClickOutside(null, true)"
                      v-if="columns[filterPopupShown.index].filter_value_from"
                      class="clear-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
            <div>
              <input v-model="columns[filterPopupShown.index].filter_value_to"
                     class="name-filter__input"
                     type="text"
                     @input="inputChange(columns[filterPopupShown.index].filter_value_to)"
                     placeholder="До">
              <button @click="columns[filterPopupShown.index].filter_value_to = ''; onClickOutside(null, true)"
                      v-if="columns[filterPopupShown.index].filter_value_to"
                      class="clear-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
          <input v-model="columns[filterPopupShown.index].filter_value"
                 class="name-filter__input"
                 type="text"
                 @input="inputChange(columns[filterPopupShown.index].filter_value)"
                 v-else
                 placeholder="Текст">
          <button @click="columns[filterPopupShown.index].filter_value = ''; onClickOutside(null, true)"
                  v-if="columns[filterPopupShown.index].filter_value"
                  class="clear-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!--FILTER POPUP-->

    <!--FILTER POPUP 2-->
    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="filterPopupShown_2 && columns_2"
         class="filter-popup"
         :class="{'last-one': filterPopupShown_2 && filterPopupShown_2.index === columns_2.length - 1}"
         :style="{ top: filterPopupShown_2.menuY + 'px', left: filterPopupShown_2.menuX + 'px' }"
    ><!--v-on-click-outside="closeFilterPopup(i)"-->
      <div class="order-filter">
        <span class="order-filter__title">Сортировать А/Я</span>
        <button @click="changeOrder_2()" class="order-filter__btn">
          <orderFilterIcon
              :isActive="columns_2[filterPopupShown_2.index].order === 'asc' || columns_2[filterPopupShown_2.index].order === 'desc'"
              :order="columns_2[filterPopupShown_2.index].order"
          />
        </button>
      </div>
      <div class="name-filter">
        <span class="name-filter__title">{{ getFilterDesc_2() }}</span>
        <div class="input-wrap">
          <v-select :options="getSelectOptions"
                    label="name"
                    v-model="columns_2[filterPopupShown_2.index].filter_value"
                    :clearable="false"
                    :searchable="true"
                    :reduce="option => option.id"
                    placeholder="Выбрать"
                    v-if="showSelect()"
                    @option:selected="onClickOutside(null, true)"
          >
            <template #no-options>
              Введите имя
            </template>
          </v-select>
          <v-select :options="getSelectOptions"
                    label="name"
                    v-model="columns_2[filterPopupShown_2.index].filter_value"
                    :clearable="false"
                    :searchable="true"
                    placeholder="Выбрать"
                    @search="fetchOptions"
                    @close="clearSelectOptions()"
                    v-else-if="showSelectSearch()"
                    @option:selected="onClickOutside(null, true)"
          >
            <template #no-options>
              Введите имя
            </template>
          </v-select>
          <VueDatePicker
            v-else-if="columns_2[filterPopupShown_2.index].date_type"
            v-model="columns_2[filterPopupShown_2.index].filter_value"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            locale="ru"
            placeholder="Дата"
            cancelText="Отмена"
            selectText="Выбрать"
            :clearable="false"
            class="popup-date-filter"
            @update:model-value="onClickOutside(null, true)"
          />
          <div v-else-if="columns_2[filterPopupShown_2.index].from_to_fields" class="popup__from-to">
            <div>
              <input v-model="columns_2[filterPopupShown_2.index].filter_value_from"
                     class="name-filter__input"
                     @input="inputChange(columns_2[filterPopupShown_2.index].filter_value_from)"
                     type="text"
                     placeholder="От">
              <button @click="columns_2[filterPopupShown_2.index].filter_value_from = ''; onClickOutside(null, true)"
                      v-if="columns_2[filterPopupShown_2.index].filter_value_from"
                      class="clear-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
            <div>
              <input v-model="columns_2[filterPopupShown_2.index].filter_value_to"
                     class="name-filter__input"
                     @input="inputChange(columns_2[filterPopupShown_2.index].filter_value_to)"
                     type="text"
                     placeholder="До">
              <button @click="columns_2[filterPopupShown_2.index].filter_value_to = ''; onClickOutside(null, true)"
                      v-if="columns_2[filterPopupShown_2.index].filter_value_to"
                      class="clear-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
          <input v-model="columns_2[filterPopupShown_2.index].filter_value"
                 class="name-filter__input"
                 @input="inputChange(columns_2[filterPopupShown_2.index].filter_value)"
                 type="text"
                 v-else
                 placeholder="Текст">
          <button @click="columns_2[filterPopupShown_2.index].filter_value = ''; onClickOutside(null, true)"
                  v-if="columns_2[filterPopupShown_2.index].filter_value"
                  class="clear-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 6L6 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6L10 10" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!--FILTER POPUP 2-->


    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="actionPopup && actionPopup.data.actionType === 'clientsManagement'"
         :style="{ top: actionPopup.menuY + 'px', right: actionPopup.menuX + 'px' }"
         class="actions-popup"><!--:class="{'last-one': k === rows.length - 1}"-->
      <button class="action-btn" @click="editClient(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.25 3.75H3.58333C3.22971 3.75 2.89057 3.89048 2.64052 4.14052C2.39048 4.39057 2.25 4.72971 2.25 5.08333V14.4167C2.25 14.7703 2.39048 15.1094 2.64052 15.3595C2.89057 15.6095 3.22971 15.75 3.58333 15.75H12.9167C13.2703 15.75 13.6094 15.6095 13.8595 15.3595C14.1095 15.1094 14.25 14.7703 14.25 14.4167V9.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.2497 2.67899C13.5244 2.40431 13.8969 2.25 14.2853 2.25C14.6738 2.25 15.0463 2.40431 15.321 2.67899C15.5957 2.95366 15.75 3.3262 15.75 3.71466C15.75 4.10311 15.5957 4.47565 15.321 4.75032L8.76178 11.3096L6 12L6.69045 9.23822L13.2497 2.67899Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Редактирование
      </button>
      <button class="action-btn" @click="setDiscountClient('provider', actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 3.75L3.75 14.25" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Установить скидки для ВИНКов
      </button>
      <button class="action-btn" @click="setDiscountClient('fuel', actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 3.75L3.75 14.25" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Установить скидки для типов топлива
      </button>
      <button class="action-btn" @click="setDiscountClient('region', actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 3.75L3.75 14.25" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Установить скидки для регионов
      </button>
<!--      <button class="action-btn" @click="setCardLimitForClient(actionPopup.data)">-->
<!--        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M9 12L15.75 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M2.25 12H3.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M6.375 13.875C7.41053 13.875 8.25 13.0355 8.25 12C8.25 10.9645 7.41053 10.125 6.375 10.125C5.33947 10.125 4.5 10.9645 4.5 12C4.5 13.0355 5.33947 13.875 6.375 13.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M14.25 6H15.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M2.25 6H9" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M11.625 7.875C12.6605 7.875 13.5 7.03553 13.5 6C13.5 4.96447 12.6605 4.125 11.625 4.125C10.5895 4.125 9.75 4.96447 9.75 6C9.75 7.03553 10.5895 7.875 11.625 7.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--        Установить лимит-->
<!--      </button>-->
      <button class="action-btn" @click="setCardsForClient(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.25 6.75H15.75M3.75 14.25H14.25C15.0784 14.25 15.75 13.5784 15.75 12.75V5.25C15.75 4.42157 15.0784 3.75 14.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V12.75C2.25 13.5784 2.92157 14.25 3.75 14.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.25 9.75H7.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Добавить карты
      </button>
      <button :disabled="actionPopup.data.user.status_id !== 1" class="action-btn green" @click="removeClient(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 5.25H4.33333H15" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Удаление
      </button>
    </div>

    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="actionPopup && actionPopup.data.actionType === 'cardActions'"
         :style="{ top: actionPopup.menuY + 'px', right: actionPopup.menuX + 'px' }"
         class="actions-popup"><!--:class="{'last-one': k === rows.length - 1}"-->
      <button class="action-btn" @click="setCardLimit(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 12L15.75 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.25 12H3.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.375 13.875C7.41053 13.875 8.25 13.0355 8.25 12C8.25 10.9645 7.41053 10.125 6.375 10.125C5.33947 10.125 4.5 10.9645 4.5 12C4.5 13.0355 5.33947 13.875 6.375 13.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.25 6H15.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.25 6H9" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.625 7.875C12.6605 7.875 13.5 7.03553 13.5 6C13.5 4.96447 12.6605 4.125 11.625 4.125C10.5895 4.125 9.75 4.96447 9.75 6C9.75 7.03553 10.5895 7.875 11.625 7.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Установить лимит
      </button>
<!--      <button class="action-btn" @click="setCardLimitForClient(actionPopup.data)">-->
<!--        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M9 12L15.75 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M2.25 12H3.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M6.375 13.875C7.41053 13.875 8.25 13.0355 8.25 12C8.25 10.9645 7.41053 10.125 6.375 10.125C5.33947 10.125 4.5 10.9645 4.5 12C4.5 13.0355 5.33947 13.875 6.375 13.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M14.25 6H15.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M2.25 6H9" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M11.625 7.875C12.6605 7.875 13.5 7.03553 13.5 6C13.5 4.96447 12.6605 4.125 11.625 4.125C10.5895 4.125 9.75 4.96447 9.75 6C9.75 7.03553 10.5895 7.875 11.625 7.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--        Установить лимит для клиента-->
<!--      </button>-->
      <button class="action-btn green" @click="suspendCard(actionPopup.data)">
        <svg v-if="actionPopup.data.status.id === 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M6 8.25V5.91667C6 5.14312 6.31607 4.40125 6.87868 3.85427C7.44129 3.30729 8.20435 3 9 3C9.79565 3 10.5587 3.30729 11.1213 3.85427C11.6839 4.40125 12 5.14312 12 5.91667V8.25" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.75 9C3.75 8.58579 4.08579 8.25 4.5 8.25H13.5C13.9142 8.25 14.25 8.58579 14.25 9V15C14.25 15.4142 13.9142 15.75 13.5 15.75H4.5C4.08579 15.75 3.75 15.4142 3.75 15V9Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-if="actionPopup.data.status.id === 1 || actionPopup.data.status.id === 3" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 11V7.88889C8 6.85749 8.42143 5.86834 9.17157 5.13903C9.92172 4.40972 10.9391 4 12 4C13.0609 4 14.0783 4.40972 14.8284 5.13903C15.5786 5.86834 16 6.85749 16 7.88889" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V12Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ actionPopup.data.status.id === 1 || actionPopup.data.status.id === 3 ? 'Разблокировать карту' : 'Заблокировать карту' }}
      </button>
      <button class="action-btn green" @click="editCard(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.25 3.75H3.58333C3.22971 3.75 2.89057 3.89048 2.64052 4.14052C2.39048 4.39057 2.25 4.72971 2.25 5.08333V14.4167C2.25 14.7703 2.39048 15.1094 2.64052 15.3595C2.89057 15.6095 3.22971 15.75 3.58333 15.75H12.9167C13.2703 15.75 13.6094 15.6095 13.8595 15.3595C14.1095 15.1094 14.25 14.7703 14.25 14.4167V9.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.2497 2.67899C13.5244 2.40431 13.8969 2.25 14.2853 2.25C14.6738 2.25 15.0463 2.40431 15.321 2.67899C15.5957 2.95366 15.75 3.3262 15.75 3.71466C15.75 4.10311 15.5957 4.47565 15.321 4.75032L8.76178 11.3096L6 12L6.69045 9.23822L13.2497 2.67899Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Редактирование
      </button>
    </div>

    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="actionPopup && actionPopup.data.actionType === 'cardClientActions'"
         :style="{ top: actionPopup.menuY + 'px', right: actionPopup.menuX + 'px' }"
         class="actions-popup">
      <button class="action-btn" @click="editClientCard(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.25 3.75H3.58333C3.22971 3.75 2.89057 3.89048 2.64052 4.14052C2.39048 4.39057 2.25 4.72971 2.25 5.08333V14.4167C2.25 14.7703 2.39048 15.1094 2.64052 15.3595C2.89057 15.6095 3.22971 15.75 3.58333 15.75H12.9167C13.2703 15.75 13.6094 15.6095 13.8595 15.3595C14.1095 15.1094 14.25 14.7703 14.25 14.4167V9.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.2497 2.67899C13.5244 2.40431 13.8969 2.25 14.2853 2.25C14.6738 2.25 15.0463 2.40431 15.321 2.67899C15.5957 2.95366 15.75 3.3262 15.75 3.71466C15.75 4.10311 15.5957 4.47565 15.321 4.75032L8.76178 11.3096L6 12L6.69045 9.23822L13.2497 2.67899Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Редактирование
      </button>
      <button class="action-btn" @click="setCardLimit(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 12L15.75 12" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.25 12H3.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.375 13.875C7.41053 13.875 8.25 13.0355 8.25 12C8.25 10.9645 7.41053 10.125 6.375 10.125C5.33947 10.125 4.5 10.9645 4.5 12C4.5 13.0355 5.33947 13.875 6.375 13.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.25 6H15.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.25 6H9" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.625 7.875C12.6605 7.875 13.5 7.03553 13.5 6C13.5 4.96447 12.6605 4.125 11.625 4.125C10.5895 4.125 9.75 4.96447 9.75 6C9.75 7.03553 10.5895 7.875 11.625 7.875Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Установить лимит
      </button>
    </div>

    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="actionPopup && actionPopup.data.actionType === 'usersManagement'"
         :style="{ top: actionPopup.menuY + 'px', right: actionPopup.menuX + 'px' }"
         class="actions-popup"><!--:class="{'last-one': k === rows.length - 1}"-->
      <button class="action-btn" @click="editUser(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.25 3.75H3.58333C3.22971 3.75 2.89057 3.89048 2.64052 4.14052C2.39048 4.39057 2.25 4.72971 2.25 5.08333V14.4167C2.25 14.7703 2.39048 15.1094 2.64052 15.3595C2.89057 15.6095 3.22971 15.75 3.58333 15.75H12.9167C13.2703 15.75 13.6094 15.6095 13.8595 15.3595C14.1095 15.1094 14.25 14.7703 14.25 14.4167V9.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.2497 2.67899C13.5244 2.40431 13.8969 2.25 14.2853 2.25C14.6738 2.25 15.0463 2.40431 15.321 2.67899C15.5957 2.95366 15.75 3.3262 15.75 3.71466C15.75 4.10311 15.5957 4.47565 15.321 4.75032L8.76178 11.3096L6 12L6.69045 9.23822L13.2497 2.67899Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Редактирование
      </button>
      <button :disabled="actionPopup.data.status !== 'Активен'" class="action-btn green" @click="removeUser(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 5.25H4.33333H15" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Удаление
      </button>
      <button class="action-btn green" @click="resetPassword(actionPopup.data)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 4.0498V7.7998H6.75" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.50611 10.7446C4.89517 11.8181 5.63259 12.7397 6.60725 13.3704C8.58659 14.6512 11.2655 14.5069 13.088 13.0077C15.9334 10.6671 15.4403 6.1401 12.2124 4.40534C11.1877 3.85465 10.0066 3.64263 8.84722 3.80121C6.54363 4.11629 4.99442 6.01082 3.375 7.5" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Сбросить пароль
      </button>
    </div>

    <div v-click-outside="onClickOutside"
         @mouseenter="mouseOverFilterPopup($event)"
         @mouseleave="mouseLeaveFilterPopup"
         v-if="actionPopup && (actionPopup.data.actionType === 'uploadPricesFiles' || actionPopup.data.actionType === 'uploadTxFiles')"
         :style="{ top: actionPopup.menuY + 'px', right: actionPopup.menuX + 'px' }"
         class="actions-popup"><!--:class="{'last-one': k === rows.length - 1}"-->
      <button @click="viewFile(actionPopup.data)" class="action-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M9.8 7.9998C9.8 8.99392 8.99411 9.7998 8 9.7998C7.00589 9.7998 6.2 8.99392 6.2 7.9998C6.2 7.00569 7.00589 6.1998 8 6.1998C8.99411 6.1998 9.8 7.00569 9.8 7.9998Z" stroke="#B0B1B2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2 7.9998C2.96008 5.54139 5.20162 3.7998 8 3.7998C10.7984 3.7998 13.0399 5.54139 14 7.9998C13.0399 10.4582 10.7984 12.1998 8 12.1998C5.20162 12.1998 2.96008 10.4582 2 7.9998Z" stroke="#B0B1B2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Посмотреть
      </button>
      <button @click="removeFile(actionPopup)" class="action-btn green">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 5.25H4.33333H15" stroke="#868685" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Удалить
      </button>
    </div>
  </div>

</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {popups} from "@/mixins/popups";
import editClientPopup from "./editClientPopup";
import viewFile from "./viewFile.vue";
import removeClientPopup from "./removeClientPopup";
import setCardLimitPopup from "./setCardLimitPopup";
import suspendCardPopup from "./suspendCardPopup";
import uploadPricesPopup from "./uploadPricesPopup";
import addCardPopup from "./addCardPopup";
import editUserPopup from "./editUserPopup";
import removeUserPopup from "./removeUserPopup";
import resetUserPasswordPopup from "./resetUserPasswordPopup";
import bunchChangeCardStatusPopup from "./bunchChangeCardStatusPopup";
import addNewUserPopup from "./addNewUserPopup";
import errorPopup from "./errorPopup.vue";
import removeFilePopup from "./removeFilePopup.vue";
import orderFilterIcon from "@/components/orderFilterIcon.vue";
import XLSX from "xlsx";
import vSelect from "vue-select";
import suretyInfo from "./suretyInfo.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import setDiscount from "./setDiscount.vue";
import setDiscountFuelType from "./setDiscountFuelType.vue";
import setDiscountRegion from "./setDiscountRegion.vue";
import editClientCard from "./editClientCard.vue";
import removeReferencesPopup from "./removeReferencesPopup.vue";
import uploadCardsFrom from "./uploadCardsFrom.vue";
import setCardsForClient from "./setCardsForClient.vue";
import integrationList from "./integrationList.vue";
import listVinksRegionsFuel from "./listVinksRegionsFuel.vue";

export default {
  name: 'allPopups',
  mixins: [popups],
  components: {
    VueDatePicker,
    vSelect,
    viewFile,
    orderFilterIcon,
    editClientPopup,
    removeClientPopup,
    setCardLimitPopup,
    suspendCardPopup,
    addCardPopup,
    editUserPopup,
    removeUserPopup,
    addNewUserPopup,
    errorPopup,
    resetUserPasswordPopup,
    bunchChangeCardStatusPopup,
    uploadPricesPopup,
    removeFilePopup,
    suretyInfo,
    setDiscount,
    setDiscountFuelType,
    setDiscountRegion,
    editClientCard,
    removeReferencesPopup,
    uploadCardsFrom,
    setCardsForClient,
    integrationList,
    listVinksRegionsFuel,
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      showOverlay: state => state.popups.overlay,
      fuelProviders: state => state.user.fuelProviders,
      stations: state => state.user.stations,
      showEditClientPopup: state => state.popups.showEditClientPopup,
      showViewFilePopup: state => state.popups.showViewFilePopup,
      showRemoveClientPopup: state => state.popups.showRemoveClientPopup,
      showSetCardLimitPopup: state => state.popups.showSetCardLimitPopup,
      showSuspendCardPopup: state => state.popups.showSuspendCardPopup,
      showAddCardPopup: state => state.popups.showAddCardPopup,
      showEditUserPopup: state => state.popups.showEditUserPopup,
      showRemoveFilePopup: state => state.popups.showRemoveFilePopup,
      showRemoveUserPopup: state => state.popups.showRemoveUserPopup,
      showAddNewUserPopup: state => state.popups.showAddNewUserPopup,
      showUploadPricesPopup: state => state.popups.showUploadPricesPopup,
      showResetPasswordPopup: state => state.popups.showResetPasswordPopup,
      showBunchChangeCardStatusPopup: state => state.popups.showBunchChangeCardStatusPopup,
      showSetCardsForClientPopup: state => state.popups.showSetCardsForClientPopup,
      showUploadCardsPopup: state => state.popups.showUploadCardsPopup,
      showSuretyInfoPopup: state => state.popups.showSuretyInfoPopup,
      tableFilterPopup: state => state.popups.tableFilterPopup,
      tableFilterPopup_2: state => state.popups.tableFilterPopup_2,
      columns: state => state.popups.columns,
      columns_2: state => state.popups.columns_2,
      actionPopup: state => state.popups.actionPopup,
      errorPopup: state => state.popups.errorPopup,
      paginationFilters: state => state.popups.paginationFilters,
      roles: state => state.user.roles,
      user_statuses: state => state.user.user_statuses,
      cardStatuses: state => state.admin.cardStatuses,
      managers: state => state.admin.managers,
      fuelTypes: state => state.user.fuelTypes,
      regions: state => state.user.regions,
      showDiscountPopup: state => state.popups.showDiscountPopup,
      showDiscountFuelTypePopup: state => state.popups.showDiscountFuelTypePopup,
      showDiscountRegionPopup: state => state.popups.showDiscountRegionPopup,
      showEditCardPopup: state => state.popups.showEditCardPopup,
      showRemoveReferencePopup: state => state.popups.showRemoveReferencePopup,
      showIntegrationListPopup: state => state.popups.showIntegrationListPopup,
      showListVinksRegionsFuelPopup: state => state.popups.showListVinksRegionsFuelPopup,
      cardLimits: state => state.user.card_limits,
    }),
    filterPopupShown: {
      get() {
        return this.tableFilterPopup
      },
      set(val) {
        this.setTableFilterPopup(val)
      }
    },
    filterPopupShown_2: {
      get() {
        return this.tableFilterPopup_2
      },
      set(val) {
        this.setTableFilterPopup_2(val)
      }
    },
    rolesOptions() {
      return this.roles.filter(item => item.name !== 'Водитель')
    },
    getSelectOptions() {
      let options = [];
      if (this.filterPopupShown?.action === 'usersManagement' && this.columns[this.filterPopupShown.index].filter_name === 'type') {
        options = this.rolesOptions;
      }
      if (this.filterPopupShown?.action === 'usersManagement' && this.columns[this.filterPopupShown.index].filter_name === 'status_id') {
        options = this.user_statuses;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'status.id') {
        options = this.cardStatuses;
      }
      if (this.filterPopupShown?.action === 'cardClientActions' && this.columns[this.filterPopupShown.index].filter_name === 'status.id') {
        options = this.cardStatuses;
      }
      if (this.filterPopupShown?.action === 'cardClientActions' && this.columns[this.filterPopupShown.index].filter_name === 'activelimits.limit_id') {
        options = this.cardLimits;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'limit_id' && this.cardLimits && this.cardLimits.length > 0) {
        options = this.cardLimits;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'card.fuel_provider_id' && this.fuelProviders && this.fuelProviders.length > 0) {
        options = this.fuelProviders;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_provider_id' && this.fuelProviders && this.fuelProviders.length > 0) {
        options = this.fuelProviders;
      }
      if (this.filterPopupShown_2?.action === 'transactionReportActions' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_provider_id' && this.fuelProviders && this.fuelProviders.length > 0) {
        options = this.fuelProviders;
      }
      if (this.filterPopupShown?.action === 'paymentsActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.status_id') {
        options = this.user_statuses;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'customer_user.status_id') {
        options = this.user_statuses;
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'manager_user.id' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      // if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'station_number' && this.stations && this.stations.length > 0) {
      //   options = this.stations;
      // }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown_2?.action === 'managerDashboardOperativeReportActions' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'manager_ids[]' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]' && this.managers && this.managers.length > 0) {
        options = this.managers;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type_month_ids[]' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'transaction.fuel_type' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'transaction.fuel_provider_id' && this.fuelProviders && this.fuelProviders.length > 0) {
        options = this.fuelProviders;
      }
      // if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'station_number' && this.stations && this.stations.length > 0) {
      //   options = this.stations;
      // }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type_year_ids[]' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'current_month' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'current_year' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_type_month' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_type_year' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'references' && this.columns[this.filterPopupShown.index].type === 'vinks' && this.columns[this.filterPopupShown.index].filter_name === 'id' && this.fuelProviders && this.fuelProviders.length > 0) {
        options = this.fuelProviders;
      }
      if (this.filterPopupShown?.action === 'references' && this.columns[this.filterPopupShown.index].type === 'fuelTypes' && this.columns[this.filterPopupShown.index].filter_name === 'id' && this.fuelTypes && this.fuelTypes.length > 0) {
        options = this.fuelTypes;
      }
      if (this.filterPopupShown?.action === 'references' && this.columns[this.filterPopupShown.index].type === 'regions' && this.columns[this.filterPopupShown.index].filter_name === 'id' && this.regions && this.regions.length > 0) {
        options = this.regions;
      }
      return options;
    }
  },
  methods: {
    ...mapActions({
      getCards: 'getCards',
      getCardsReport: 'getCardsReport',
      getCustomers: 'getCustomers',
      getAllUsers: 'getAllUsers',
      getTransactionReport: 'getTransactionReport',
      getReviseReport: 'getReviseReport',
      getManagerDashboard: 'getManagerDashboard',
      getPayments: 'getPayments',
      getPricesFiles: 'getPricesFiles',
      getTransactionFiles: 'getTransactionFiles',
      getFileByPath: 'getFileByPath',
      getUsersSearch: 'getUsersSearch',
      getCardStatuses: 'getCardStatuses',
      getOperationsReport: 'getOperationsReport',
      getAccountantDashboard: 'getAccountantDashboard',
      getCardCatalog: 'getCardCatalog',
    }),
    ...mapMutations({
      setTableFilterPopup: 'SET_TABLE_FILTER_POPUP',
      setTableFilterPopup_2: 'SET_TABLE_FILTER_POPUP_2',
      setMouseOverFilter: 'SET_MOUSE_OVER_FILTER',
      setColumns: 'SET_COLUMNS',
      setColumns_2: 'SET_COLUMNS_2',
      setClient: 'SET_CLIENT',
      showEditClient: 'SHOW_EDIT_CLIENT',
      showRemoveClient: 'SHOW_REMOVE_CLIENT',
      showRemoveFile: 'SHOW_REMOVE_FILE',
      setActionPopup: 'SET_ACTION_POPUP',
      setCard: 'SET_CARD',
      setCustomer: 'SET_CUSTOMER',
      showSetCardLimit: 'SHOW_SET_CARD_LIMIT',
      showSetCardLimitCustomer: 'SHOW_SET_CARD_LIMIT_CUSTOMER',
      showSuspendCard: 'SHOW_SUSPEND_CARD',
      setUser: 'SET_USER',
      showEditUser: 'SHOW_EDIT_USER',
      showRemoveUser: 'SHOW_REMOVE_USER',
      showResetPassword: 'SHOW_RESET_PASSWORD',
      showViewFile: 'SHOW_VIEW_FILE',
      setFile: 'SET_FILE',
      setPaginationFilters: 'SET_PAGINATION_FILTERS',
      setPaginationFilters_2: 'SET_PAGINATION_FILTERS_2',
      setManagers: 'SET_MANAGERS',
      setShowDiscountPopup: 'SET_SHOW_DISCOUNT_POPUP',
      setShowDiscountFuelTypePopup: 'SET_SHOW_DISCOUNT_FUEL_TYPE_POPUP',
      setShowDiscountRegionPopup: 'SET_SHOW_DISCOUNT_REGION_POPUP',
      setShowEditCardPopup: 'SET_SHOW_EDIT_CARD_POPUP',
      setDataEditCardPopup: 'SET_DATA_EDIT_CARD_POPUP',
      toggleOverlay: 'TOGGLE_OVERLAY',
      showAddCard: 'SHOW_ADD_CARD',
      setEditCardData: 'SET_EDIT_CART_DATA_POPUP',
      setShowCardsForClientPopup: 'SET_SHOW_CARDS_FOR_CLIENT_POPUP',
    }),
    changeOrder() {
      let newOrderData = JSON.parse(JSON.stringify(this.columns[this.tableFilterPopup.index]))

      if (!this.columns[this.tableFilterPopup.index].order || this.columns[this.tableFilterPopup.index].order === '') {
        // newOrderData = JSON.parse(JSON.stringify(this.columns[this.tableFilterPopup.index]))
        newOrderData.order = 'desc'
      } else if (this.columns[this.tableFilterPopup.index].order === 'desc') {
        // newOrderData = JSON.parse(JSON.stringify(this.columns[this.tableFilterPopup.index]))
        newOrderData.order = 'asc'
      } else if (this.columns[this.tableFilterPopup.index].order === 'asc') {
        // newOrderData = JSON.parse(JSON.stringify(this.columns[this.tableFilterPopup.index]))
        newOrderData.order = ''
      }

      let newColumns = JSON.parse(JSON.stringify(this.columns))
      for (let i = 0; i < newColumns.length; i++) {
        let column = newColumns[i]
        if (column.order) {
          column.order = ''
        }
      }
      newColumns[this.tableFilterPopup.index] = newOrderData
      this.setColumns(newColumns)

      this.onClickOutside(null, true)
    },
    changeOrder_2() {
      let newOrderData = JSON.parse(JSON.stringify(this.columns_2[this.tableFilterPopup_2.index]))

      if (!this.columns_2[this.tableFilterPopup_2.index].order || this.columns_2[this.tableFilterPopup_2.index].order === '') {
        // let newOrderData = JSON.parse(JSON.stringify(this.columns_2[this.tableFilterPopup_2.index]))
        newOrderData.order = 'desc'
      } else if (this.columns_2[this.tableFilterPopup_2.index].order === 'desc') {
        // let newOrderData = JSON.parse(JSON.stringify(this.columns_2[this.tableFilterPopup_2.index]))
        newOrderData.order = 'asc'
      } else if (this.columns_2[this.tableFilterPopup_2.index].order === 'asc') {
        // let newOrderData = JSON.parse(JSON.stringify(this.columns_2[this.tableFilterPopup_2.index]))
        newOrderData.order = ''
      }

      let newColumns = JSON.parse(JSON.stringify(this.columns_2))
      for (let i = 0; i < newColumns.length; i++) {
        let column = newColumns[i]
        if (column.order) {
          column.order = ''
        }
      }
      newColumns[this.tableFilterPopup_2.index] = newOrderData
      this.setColumns_2(newColumns)

      this.onClickOutside(null, true)
    },
    mouseLeaveFilterPopup(event) {
      this.filterPopupShown = null;
      this.filterPopupShown_2 = null;
      this.setActionPopup(null)
      setTimeout(() => {
        this.setMouseOverFilter(false)
      }, 100)

      this.onClickOutside(event)
    },
    mouseOverFilterPopup(event) {
      this.setMouseOverFilter(true)
    },
    inputChange(value) {
      // if (value && value.length >=3) {
      //   this.onClickOutside(null, true)
      // }
      this.onClickOutside(null, true)
    },
    async onClickOutside(event, keepOpen = false) {

      this.setColumns(this.columns)
      this.setColumns_2(this.columns_2)

      let args = [
        {
          name: 'page',
          value: 1
        },
        {
          name: 'perPage',
          value: this.paginationFilters[1].value
        }
      ]

      this.setPaginationFilters(args)

      this.loading = true;
      if (this.filterPopupShown?.action === 'cardActions') {
        await this.getCards()
      } else if (this.filterPopupShown?.action === 'cardsReportType') {
        await this.getCardsReport()
      } else if (this.filterPopupShown?.action === 'cardClientActions') {
        await this.getCards()
      } else if (this.filterPopupShown?.action === 'clientsManagement') {
        await this.getCustomers()
      } else if (this.filterPopupShown_2?.action === 'clientsManagement') {
        await this.getCustomers()
      } else if (this.filterPopupShown?.action === 'usersManagement') {
        await this.getAllUsers()
      } else if (this.filterPopupShown?.action === 'transactionReportActions') {
        await this.getTransactionReport()
      } else if (this.filterPopupShown?.action === 'reviseReport') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getReviseReport()
      } else if (this.filterPopupShown?.action === 'managerDashboardActions') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getManagerDashboard()
      } else if (this.filterPopupShown?.action === 'paymentsActions') {
        await this.getPayments()
      } else if (this.filterPopupShown?.action === 'uploadPricesFiles') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getPricesFiles()
      } else if (this.filterPopupShown?.action === 'uploadTxFiles') {
        await this.getTransactionFiles()
      } else if (this.filterPopupShown_2?.action === 'managerDashboardOperativeReportActions') {
        args[1].name = 'page_size'
        this.setPaginationFilters_2(args)
        await this.getOperationsReport()
      } else if (this.filterPopupShown?.action === 'accountantDashboardActions') {
        args[1].name = 'page_size'
        this.setPaginationFilters(args)
        await this.getAccountantDashboard()
      }
      this.loading = false;

      if (keepOpen === false) {
        this.filterPopupShown = null;
        this.filterPopupShown_2 = null;
        this.setActionPopup(null)
      }
    },
    async viewFile(data) {
      this.setActionPopup(null)
      await this.getFileByPath(data).then(res => {
        this.setFile({filename: data.filename, res: res})
        this.toggleOverlay(true)
        this.showViewFile(true)
      });
    },
    removeFile(data) {
      this.setActionPopup(null)
      this.setFile(data.data)
      this.toggleOverlay(true)
      this.showRemoveFile(true)
    },
    editClient(client) {
      this.setActionPopup(null)
      this.setClient(client)
      this.toggleOverlay(true)
      this.showEditClient(true)
    },
    removeClient(client) {
      this.setActionPopup(null)
      this.setClient(client)
      this.toggleOverlay(true)
      this.showRemoveClient(true)
    },
    setCardLimit(card) {
      this.loading = true;
      this.getCardCatalog(card.id).then(res => {
        this.loading = false;
        this.setActionPopup(null)
        this.setCard(card)
        this.toggleOverlay(true)
        this.showSetCardLimitCustomer(false)
        this.showSetCardLimit(true)
      }).catch(e => {
        this.loading = false;
      });
    },
    setCardLimitForClient(client) {
      this.setActionPopup(null)
      this.setCustomer(client)
      this.toggleOverlay(true)
      this.showSetCardLimitCustomer(true)
      this.showSetCardLimit(true)
    },
    setCardsForClient(client) {
      this.setActionPopup(null)
      this.setCustomer(client)
      this.toggleOverlay(true)
      this.setShowCardsForClientPopup(true)
    },
    setDiscountClient(flag, client) {
      if (flag === 'provider') {
        this.setActionPopup(null)
        this.setCustomer(client)
        this.toggleOverlay(true)
        this.setShowDiscountPopup(true)
      }
      if (flag === 'fuel') {
        this.setActionPopup(null)
        this.setCustomer(client)
        this.toggleOverlay(true)
        this.setShowDiscountFuelTypePopup(true)
      }
      if (flag === 'region') {
        this.setActionPopup(null)
        this.setCustomer(client)
        this.toggleOverlay(true)
        this.setShowDiscountRegionPopup(true)
      }
    },
    suspendCard(card) {
      this.setActionPopup(null)
      this.setCard(card)
      this.toggleOverlay(true)
      this.showSuspendCard(true)
    },
    editUser(user) {
      this.setActionPopup(null)
      this.setUser(user)
      this.toggleOverlay(true)
      //this.showEditUser(true)
      this.showEditUser(true)
    },
    editClientCard(data) {
      this.setActionPopup(null)
      this.setDataEditCardPopup(data)
      this.toggleOverlay(true)
      this.setShowEditCardPopup(true)
    },
    editCard(data) {
      this.setEditCardData(data)
      this.toggleOverlay(true)
      this.showAddCard(true)
    },
    removeUser(user) {
      this.setActionPopup(null)
      this.setUser(user)
      this.toggleOverlay(true)
      this.showRemoveUser(true)
    },
    resetPassword(user) {
      this.setActionPopup(null)
      this.setUser(user)
      this.toggleOverlay(true)
      this.showResetPassword(true)
    },
    getFilterDesc() {
      if (this.showSelect()) {
        return 'По названию'
      } else if (this.showSelectSearch()) {
        return 'По названию'
      } else if (this.columns[this.filterPopupShown.index].date_type) {
        return 'По дате'
      } else if (this.columns[this.filterPopupShown.index].from_to_fields) {
        return 'По значению'
      } else {
        return 'По названию'
      }
    },
    getFilterDesc_2() {
      if (this.showSelect()) {
        return 'По названию'
      } else if (this.showSelectSearch()) {
        return 'По названию'
      } else if (this.columns_2[this.filterPopupShown_2.index].date_type) {
        return 'По дате'
      } else if (this.columns_2[this.filterPopupShown_2.index].from_to_fields) {
        return 'По значению'
      } else {
        return 'По названию'
      }
    },
    showSelect() {
      let flag = false;
      if (this.filterPopupShown?.action === 'usersManagement' && this.columns[this.filterPopupShown.index].filter_name === 'type') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'usersManagement' && this.columns[this.filterPopupShown.index].filter_name === 'status_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'status.id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'card.fuel_provider_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_provider_id') {
        flag = true;
      }
      if (this.filterPopupShown_2?.action === 'transactionReportActions' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_provider_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardClientActions' && this.columns[this.filterPopupShown.index].filter_name === 'status.id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardClientActions' && this.columns[this.filterPopupShown.index].filter_name === 'activelimits.limit_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'limit_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'transaction.fuel_type') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'transaction.fuel_provider_id') {
        flag = true;
      }
      // if (this.filterPopupShown?.action === 'cardsReportType' && this.columns[this.filterPopupShown.index].filter_name === 'station_number') {
      //   flag = true;
      // }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.status_id') {
        flag = true;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'customer_user.status_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type') {
        flag = true;
      }
      // if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'station_number') {
      //   flag = true;
      // }
      if (this.filterPopupShown?.action === 'references' && this.columns[this.filterPopupShown.index].filter_name === 'id') {
        flag = true;
      }
      return flag;
    },
    showSelectSearch() {
      let flag = false;
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'paymentsActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'manager_user.id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type_month_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'fuel_type_year_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown_2?.action === 'managerDashboardOperativeReportActions' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'manager_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'current_month') {
        flag = true;
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'current_year') {
        flag = true;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_type_month') {
        flag = true;
      }
      if (this.filterPopupShown_2?.action === 'clientsManagement' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'fuel_type_year') {
        flag = true;
      }
      return flag;
    },
    async fetchOptions (search, loading) {
      // if (search.length >= 3) {
      //
      // }
      loading(true)
      if (this.filterPopupShown?.action === 'cardActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'paymentsActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'customer_user.id') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'clientsManagement' && this.columns[this.filterPopupShown.index].filter_name === 'manager_user.id') {
        await this.getUsersSearch({
          search,
          type: 2
        })
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'managerDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]') {
        await this.getUsersSearch({
          search,
          type: 2
        })
      }
      if (this.filterPopupShown_2?.action === 'managerDashboardOperativeReportActions' && this.columns_2[this.filterPopupShown_2.index].filter_name === 'manager_ids[]') {
        await this.getUsersSearch({
          search,
          type: 2
        })
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'users_ids[]') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'transactionReportActions' && this.columns[this.filterPopupShown.index].filter_name === 'customer_id') {
        await this.getUsersSearch({
          search,
          type: 3
        })
      }
      if (this.filterPopupShown?.action === 'accountantDashboardActions' && this.columns[this.filterPopupShown.index].filter_name === 'manager_ids[]') {
        await this.getUsersSearch({
          search,
          type: 2
        })
      }
      loading(false)
    },
    clearSelectOptions() {
      this.setManagers([]);
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/variables";
.filter-popup {
  height: 108px;
  position: absolute;
  z-index: 11;
  bottom: -102px;
  left: 0;
  cursor: default;

  display: inline-flex;
  padding: 4px 4px 12px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 0.5px solid $back;
  background: #FFFFFF;
  box-sizing: border-box;

  /* 1 */
  box-shadow: 0px 2px 4px 0px rgba(26, 26, 24, 0.24);

  &:hover {
    background: #FFFFFF;
  }

  .order-filter {
    display: flex;
    padding: 7px 7px 7px 8px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 46px;
    width: calc(100% - 15px);

    &__title {
      color: $black;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      white-space: nowrap;
    }

    &__btn {
      width: 18px;
      height: 18px;
      padding: 0;
      border: none;
      background: #FFFFFF;
    }
  }

  .name-filter {
    display: flex;
    width: 170px;
    padding: 8px 8px 0px 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 6px;

    &__title {
      color: $grey-2;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    &__input {
      display: flex;
      height: 30px;
      padding: 0 24px 0 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid $back;
      background: #FFFFFF;

      color: $black;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: SF_Pro_Regular, Arial, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */

      &:active, &:focus, &:focus-visible {
        border-color: $green;
        outline: none;
      }
    }

    .input-wrap {
      position: relative;

      .clear-btn {
        position: absolute;
        top: 50%;
        right: 9px;
        /* bottom: 8px; */
        width: 16px;
        height: 16px;
        padding: 0;
        border: none;
        background: #FFFFFF;
        transform: translateY(-50%);
      }

      @media (max-width: 1000px) {
        .v-select {
          height: 30px;
          .vs__dropdown-toggle {
            height: 30px;
          }
        }
      }
    }
  }

  &.last-one {
    left: -95px;
  }
}

.actions-popup {
  min-width: 160px;
  width: max-content;
  //height: 64px;
  height: min-content;
  position: absolute;
  z-index: 12;
  bottom: -30px;
  right: 0;
  cursor: default;

  display: inline-flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: #FFFFFF;

  /* 1 */
  box-shadow: 0px 2px 4px 0px rgba(109, 181, 52, 0.30);

  .action-btn {
    display: flex;
    padding: 7px 25px 7px 11px;
    align-items: flex-start;
    gap: 10px;
    background: #FFFFFF;
    border: none;
    outline: none;
    width: 100%;

    color: $black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Regular, Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */

    &:hover {
      background: $back;
      svg {
        path {
          stroke: $green;
        }
      }
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        background: none;
        svg {
          path {
            stroke: #868685;
          }
        }
      }
    }
  }

  &.last-one {
    bottom: 10px;
    right: unset;
    left: -170px;
  }

  &.cards {
    bottom: -170px;
    right: -4px;
    z-index: 11;
  }

  &.cards-limits {
    bottom: -80px;
    right: -4px;
    z-index: 11;
  }
}
</style>
